import * as React from "react"
import styled, { ThemeProvider } from "styled-components"
import { up } from "styled-breakpoints"
import IframeResizer from "iframe-resizer-react"

import { H1, P } from "../components/ui/text"
import Meta from "../components/meta"

const theme = {
  breakpoints: {
    xs: "0px",
    sm: "370px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1600px",
  },
}

export default class DemoPage extends React.Component{

  componentDidMount(){

    if (typeof window !== `undefined`) {
      window.addEventListener('beforeunload', function (e) {
        e.preventDefault();
        e.returnValue = ''
      })
    }

  }

  render(){
    return(
      <ThemeProvider theme={theme}>
        <Meta />
        <Wrapper>
          <Header>
            <Row>
              <Logo src="https://www.qantas.com/content/dam/qantas/centenary/QANTAS-Centenary-24px.svg" />
            </Row>
          </Header>
          <SubHeader />
          <Banner />
          <Lorum>
            <Row>
              <H1>Iframe resizer demo</H1>
              <P>
                Earn 100,000 bonus Qantas Points* when you apply for a new Qantas
                American Express Ultimate Card online by 5 April 2021, are
                approved and spend $3,000 on eligible purchases on your new Card
                within the first three months*. Plus, enjoy a $450 Qantas Travel
                Credit to use on eligible Qantas Flights each year.^
              </P>
            </Row>
          </Lorum>
          <Points>
            <Container>
              <IFrameResizer 
                src="/campaign/new-campaign?campaign=new-campaign" 
                background="#F4F5F6"
                scrolling="no"
                bodyPadding="0px 0px 30px 0px"
              />
            </Container>
          </Points>
          <Lorum>
            <Row>
              <H1>Discover where Qantas Points can take you</H1>
              <P>
                Earn 100,000 bonus Qantas Points* when you apply for a new Qantas
                American Express Ultimate Card online by 5 April 2021, are
                approved and spend $3,000 on eligible purchases on your new Card
                within the first three months*. Plus, enjoy a $450 Qantas Travel
                Credit to use on eligible Qantas Flights each year.^
              </P>
            </Row>
          </Lorum>
          <Lorum>
            <Row>
              <H1>Discover where Qantas Points can take you</H1>
              <P>
                Earn 100,000 bonus Qantas Points* when you apply for a new Qantas
                American Express Ultimate Card online by 5 April 2021, are
                approved and spend $3,000 on eligible purchases on your new Card
                within the first three months*. Plus, enjoy a $450 Qantas Travel
                Credit to use on eligible Qantas Flights each year.^
              </P>
            </Row>
          </Lorum>
          <Lorum>
            <Row>
              <H1>Discover where Qantas Points can take you</H1>
              <P>
                Earn 100,000 bonus Qantas Points* when you apply for a new Qantas
                American Express Ultimate Card online by 5 April 2021, are
                approved and spend $3,000 on eligible purchases on your new Card
                within the first three months*. Plus, enjoy a $450 Qantas Travel
                Credit to use on eligible Qantas Flights each year.^
              </P>
            </Row>
          </Lorum>
          <Lorum>
            <Row>
              <H1>Discover where Qantas Points can take you</H1>
              <P>
                Earn 100,000 bonus Qantas Points* when you apply for a new Qantas
                American Express Ultimate Card online by 5 April 2021, are
                approved and spend $3,000 on eligible purchases on your new Card
                within the first three months*. Plus, enjoy a $450 Qantas Travel
                Credit to use on eligible Qantas Flights each year.^
              </P>
            </Row>
          </Lorum>
        </Wrapper>
      </ThemeProvider>
    )
  }

}


const Wrapper = styled.div`
  padding-bottom: 120px;
`

const Header = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #323232;
`

const SubHeader = styled.div`
  background: #323232;
  height: 64px;
`

const Row = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;

  ${up("xl")} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Logo = styled.img`
  width: 292px;
`

const Banner = styled.div`
  height: 320px;
  background: #dadada;

  ${up("md")} {
    height: 640px;
  }
`

const Points = styled.div`
  text-align: center;
`

const Container = styled.div`
  
`

const IFrameResizer = styled(IframeResizer)`
  border: none;
  width: 100%;
  height: 900px;
  overflow: hidden;
  background:${props => props.background};

  ${up("md")} {
    height: 850px;
  }
  
  ${up("lg")} {
    height: 850px;
  }
`

const Lorum = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background:${props => props.background};
  text-align:center;
`
